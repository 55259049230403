import Link, { LinkProps } from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import { useMemo } from 'react'
import url from 'url'

type ColorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'black'
  | 'black-underlined'
  | 'none'
  | 'white'
  | 'white-underline'

interface InternalLinkProps extends LinkProps {
  className?: string
  children: React.ReactNode
  color?: ColorType
  openInNewTab?: boolean
  download?: boolean
  highlightActive?: boolean
}

export const InternalLink = ({
  className = '',
  children,
  color = 'primary',
  href,
  openInNewTab = false,
  download = false,
  highlightActive,
  ...props
}: InternalLinkProps) => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const isActiveLink = useMemo(() => {
    const hrefUrl = typeof href === 'string' ? url.parse(href) : href

    if (hrefUrl.search) {
      return (
        url.format(hrefUrl) ===
        url.format({
          pathname,
          search: searchParams?.toString()
        })
      )
    }
    return hrefUrl.pathname === pathname
  }, [href, pathname, searchParams])

  const buildLinkColorClasses = () => {
    if (isActiveLink && highlightActive)
      return 'text-primary hover:text-primaryDark'

    switch (color) {
      case 'primary':
        return 'text-primary hover:text-primaryDark'
      case 'secondary':
        return 'text-secondary hover:text-secondaryDark'
      case 'tertiary':
        return 'text-tertiary hover:text-tertiaryDark'
      case 'black':
        return 'text-charcoal hover:text-primary'
      case 'black-underlined':
        return 'font-normal underline text-black hover:no-underline'
      case 'white':
        return 'text-charcoal hover:text-primary'
      case 'white-underline':
        return 'font-normal underline text-white hover:no-underline'
      default:
        return ''
    }
  }

  return (
    <Link
      href={href}
      download={download}
      {...props}
      target={openInNewTab || download ? '_blank' : undefined}
      className={`font-medium transition-colors duration-150 ease-in-out ${buildLinkColorClasses()} ${className}`}
    >
      {children}
    </Link>
  )
}
