import {
  GetAdminAccountApplicationsViewQuery,
  GetAdminAccountQuery,
  GetAdminAccountRegistriesQuery,
  GetAccountQuery,
  ListAdminAccountsQuery
} from 'src/config/generated/graphql'

import { ApplicationWorkflowState } from './application'
import { ArrayElement } from './common'

// GQL types

export type AdminListAccountType = ArrayElement<
  ListAdminAccountsQuery['Account']
>
export type AdminAccountRegistryType = ArrayElement<
  GetAdminAccountRegistriesQuery['RegistryView']
>
export type AdminAccountApplicationViewType = ArrayElement<
  GetAdminAccountApplicationsViewQuery['ApplicationView']
> & {
  WorkflowState: ApplicationWorkflowState
}
export type AdminAccountType = NonNullable<GetAdminAccountQuery['Account']>
export type AdminMembershipType = ArrayElement<AdminAccountType['Memberships']>

export type GetAccountType = NonNullable<GetAccountQuery['account']>

// Frontend types and enums

export enum AccountType {
  Unknown = 'Unknown',
  IndividualFamily = 'IndividualFamily',
  REM_FamilyHousehold = 'REM_FamilyHousehold',
  FarmingForestryRural = 'FarmingForestryRural',
  Business = 'Business',
  SchoolTertiary = 'SchoolTertiary',
  Community = 'Community',
  NGOTrust = 'NGOTrust',
  Government = 'Government',
  IwiMaori = 'IwiMaori',
  NurseryTreeSupplier = 'NurseryTreeSupplier'
}

export const ACCOUNT_TYPE_VALUES: { [key: string]: string } = {
  [AccountType.Unknown]: 'Unknown',
  [AccountType.IndividualFamily]: 'Individual / Family',
  [AccountType.REM_FamilyHousehold]: 'Removed (not longer in use)',
  [AccountType.FarmingForestryRural]: 'Farming / Forestry / Rural',
  [AccountType.Business]: 'Business',
  [AccountType.SchoolTertiary]: 'School / Tertiary',
  [AccountType.Community]: 'Community Organisation',
  [AccountType.NGOTrust]: 'NGO / Trust / Philanthropic Organisation',
  [AccountType.Government]: 'Government, Local / National / Agency',
  [AccountType.IwiMaori]: 'Iwi / Maori Organisation',
  [AccountType.NurseryTreeSupplier]: 'Nursery / Tree Supplier'
}

// from the Membership
export enum MembershipRoleType {
  Unknown = 0,
  Member,
  Owner,
  Admin
}

export enum SectorType {
  Unknown = 'Unknown',
  DairyFarming = 'Dairy Farming',
  SheepCattleFarming = 'Sheep / Cattle Farming',
  OtherLivestockFarming = 'Other Livestock Farming',
  PlantCrops = 'Plant Crops (grain, seed crops, fruit, vegetables, wine grapes, nursery plants',
  OtherFarmPractisesServices = 'Other Farm Practises / Services',
  Forestry = 'Forestry',
  LifestyleBlock = 'Lifestyle Block'
}

export enum SchoolTertiaryType {
  Unknown = 'Unknown',
  ECE = 'Early Childhood Education',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary'
}

export enum BusinessType {
  Unknown = 'Unknown',
  AccommodationFoodServices = 'Accommodation and Food Services',
  AdministrativeSupport = 'Administrative and Support services',
  AgricultureForestryFishing = 'Agriculture, Forestry and Fishing',
  ArtsRecreation = 'Arts and Recreation Services',
  Construction = 'Construction',
  EducationTraining = 'Education and Training',
  ElectricityGasWaterWaste = 'Electricity, Gas, Water and Waste Services',
  FinancialInsurance = 'Financial and Insurance Services',
  HealthCareSocial = 'Health Care and Social Assistance',
  InformationMediaTelecommunication = 'Information Media and Telecommunication',
  Manufacturing = 'Manufacturing',
  Mining = 'Mining',
  ProfessionalScientificTechnical = 'Professional, Scientific and Technical Services',
  PublicAdminSafety = 'Public Administration and Safety',
  RentalHiringRealEstate = 'Rental, Hiring and Real Estate Services',
  RetailTrade = 'Retail Trade',
  TransportPostalWarehousing = 'Transport, Postal and Warehousing',
  WholesaleTrade = 'Wholesale Trade',
  OtherServices = 'Other Services'
}

export enum OrganisationType {
  Unknown = 'Unknown',
  CharitableTrust = 'Registered Charitable Trust',
  DoneeOrganisationTrust = 'Donee Organisation / Trust',
  IncorporatedSociety = 'Incorporated Society',
  CommunityTrust = 'Community Trust',
  OtherCommunityGroup = 'Other Community Group'
}

export enum FocusAreaType {
  Unknown = 'Unknown',
  ConservationOfArea = 'Conservation of designated area',
  PestControl = 'Pest Control',
  Planting = 'Planting',
  SpeciesProtectionFlora = 'Species Protection (Flora)',
  SpeciesProtectionFauna = 'Species Protection (Fauna)',
  Other = 'Other'
}

export enum GovernmentType {
  Unknown = 'Unknown',
  LocalGovernment = 'Local Government',
  CentralGovernment = 'Central Government',
  StateOwnedEnterprise = 'State Owned Enterprise',
  OtherGovernmentAgency = 'Other Government Agency'
}

export enum BusinessSize {
  SelfEmployed = 'SelfEmployed',
  Micro = 'Micro',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  Enterprise = 'Enterprise'
}

export enum RoleOwnerType {
  Unknown = 0,
  Individual,
  Group
}

export enum ActiveProfileType {
  Funder = 'Funder',
  Planter = 'Planter'
}
