import { ApolloQueryResult } from '@apollo/client'
import { setUser as setSentryUser } from '@sentry/nextjs'
import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useAuth } from 'src/providers/AuthProvider'
import { NotificationType, notify } from 'src/providers/NotificationProvider'

import {
  GetUserAndMembershipsQuery,
  namedOperations,
  UpdateActiveAccountIdMutationHookResult,
  useGetUserAndMembershipsLazyQuery,
  useUpdateActiveAccountIdMutation
} from 'src/config/generated/graphql'
import {
  adminListApplicationsPath,
  adminTreePoolPath,
  dashboardPath,
  homePath
} from 'src/config/paths'
import { MembershipRoleType } from 'src/types/account'

type RequiredKeepUndefined<T> = { [K in keyof T]-?: [T[K]] } extends infer U
  ? U extends Record<keyof U, [any]>
    ? { [K in keyof U]: U[K][0] }
    : never
  : never

export type UserProviderUserType = RequiredKeepUndefined<
  GetUserAndMembershipsQuery['User']
>

export type UserProviderMembershipsType =
  GetUserAndMembershipsQuery['Membership']

export interface UserContextProps {
  user?: UserProviderUserType
  memberships?: UserProviderMembershipsType
  isLoading: boolean
  refetchUser: () => Promise<ApolloQueryResult<GetUserAndMembershipsQuery>>
  updateActiveAccountId: UpdateActiveAccountIdMutationHookResult[0]
  isAdminMode: boolean
  setAdminMode: (adminMode: boolean) => void
  isAccountOwner: boolean
}

export const UserContext = createContext<UserContextProps>(
  {} as UserContextProps
)

export const UserProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const router = useRouter()
  const { userId, isLoading: authLoading, isAdvisor, isStaff } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [user, setUser] = useState<UserProviderUserType | undefined>()
  const [memberships, setMemberships] = useState<
    GetUserAndMembershipsQuery['Membership'] | undefined
  >()
  const [isAdminMode, setIsAdminMode] = useState(false)
  const [isAccountOwner, setIsAccountOwner] = useState(false)

  const [
    getUserAndMemberships,
    {
      loading: userAndMembershipsLoading,
      called: userAndMembershipsCalled,
      refetch
    }
  ] = useGetUserAndMembershipsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        let activeProfileType = data.User?.ActiveProfileType
        if (!activeProfileType) {
          if (user?.Account?.PlanterId && user.Account.FunderId) {
            activeProfileType = 'Funder'
          } else if (user?.Account?.PlanterId) {
            activeProfileType = 'Planter'
          } else {
            activeProfileType = 'Funder'
          }
        }
        setUser({
          ...data.User,
          ActiveProfileType: activeProfileType
        } as UserProviderUserType)
        setMemberships(data.Membership)
      }
      setIsLoading(false)
    }
  })

  const [updateActiveAccountId] = useUpdateActiveAccountIdMutation({
    refetchQueries: [namedOperations.Query.getUserAndMemberships],
    awaitRefetchQueries: true,
    onCompleted: () => {
      router.push(dashboardPath())
    }
  })

  useEffect(() => {
    setIsAccountOwner(
      memberships?.find(m => m.AccountId === user?.ActiveAccountId)?.Type ===
        MembershipRoleType.Owner
    )
  }, [memberships, user?.ActiveAccountId])

  useEffect(() => {
    setIsAdminMode(JSON.parse(window.localStorage.getItem('IS_ADMIN_MODE')!))
  }, [])

  useEffect(() => {
    if (userAndMembershipsCalled && !userAndMembershipsLoading)
      setIsLoading(false)
  }, [userAndMembershipsLoading, userAndMembershipsCalled])

  // watches auth provider context data and hydrates user data accordingly
  useEffect(() => {
    if (!authLoading) {
      if (userId) {
        // user is logged in, fetch user data
        getUserAndMemberships({ variables: { id: userId } })
      } else {
        // user is not logged in
        setIsLoading(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, authLoading])

  // keeps the sentry user in sync with our logged in user for accurate error reporting
  useEffect(() => {
    setSentryUser(
      user
        ? {
            email: user.Email,
            username: user.FirstName
          }
        : null
    )
  }, [user])

  // Note - Auth0 should be the only thing redirecting back to the app with error_description/error/state query params
  useEffect(() => {
    if (
      router.query.error === 'access_denied' &&
      router.query.error_description &&
      router.query.state
    ) {
      notify({
        id: 'auth0_redirect_error',
        type: NotificationType.error,
        message: router.query.error_description as string
      })
    }
  }, [router.query])

  const setAdminMode = useCallback(
    (adminMode: boolean) => {
      window.localStorage.setItem('IS_ADMIN_MODE', JSON.stringify(adminMode))
      setIsAdminMode(adminMode)

      let path = homePath
      if (isAdvisor && adminMode) {
        path = adminListApplicationsPath()
      } else if (isStaff && adminMode) {
        path = adminTreePoolPath
      }

      router.replace(path)
    },
    [isAdvisor, isStaff, router]
  )

  const memoisedValue = useMemo(
    () => ({
      user,
      memberships,
      isLoading,
      updateActiveAccountId,
      refetchUser: refetch,
      isAdminMode,
      setAdminMode,
      isAccountOwner
    }),
    [
      user,
      memberships,
      isLoading,
      updateActiveAccountId,
      refetch,
      isAdminMode,
      setAdminMode,
      isAccountOwner
    ]
  )

  return (
    <UserContext.Provider value={memoisedValue}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
