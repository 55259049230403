import { useContext, createContext, useMemo, useState } from 'react'

export type ModalPropsType = { [prop: string]: unknown }

export enum ModalType {
  ApproveApplication = 'ApproveApplication',
  DeclineApplication = 'DeclineApplication',
  CloseApplication = 'CloseApplication',
  ReturnApplicationToPending = 'ReturnApplicationToPending',
  ReopenApplication = 'ReopenApplication',
  WithdrawApplication = 'WithdrawApplication',
  SubmitApplication = 'SubmitApplication',
  Cancel = 'Cancel',
  UpdateApplicationCounts = 'UpdateApplicationCounts',
  UpdatePurchaseRecipientEmail = 'UpdatePurchaseRecipientEmail',
  UpdateApplicationSeason = 'UpdateApplicationSeason',
  DeleteApplication = 'DeleteApplication',
  DeletePlanting = 'DeletePlanting',
  SubmitPlanting = 'SubmitPlanting',
  UpdateProjectVisibility = 'UpdateProjectVisibility',
  FeatureProject = 'FeatureProject',
  CancelSubscription = 'CancelSubscription',
  RemovePaymentMethod = 'RemovePaymentMethod',
  InviteAccountUser = 'InviteAccountUser',
  UpdateMembershipRole = 'UpdateMembershipRole',
  RemoveMembership = 'RemoveMembership',
  ResendInvite = 'ResendInvite',
  DeleteInvite = 'DeleteInvite',
  SaveAsDraft = 'SaveAsDraft',
  DeleteProject = 'DeleteProject',
  CreateAccountProfileType = 'CreateAccountProfileType'
}

export interface ModalContextProps {
  closeModal: () => void
  isModalOpen: boolean
  modalProps: ModalPropsType
  modalType?: ModalType
  openModal: (modalType: ModalType, props?: ModalPropsType) => void
}

export const ModalContext = createContext<ModalContextProps>(
  {} as ModalContextProps
)

export const ModalProvider = ({ children }: { children: any }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined)
  const [modalProps, setModalProps] = useState<ModalPropsType>({})

  function openModal(type: ModalType, props?: ModalPropsType) {
    setIsModalOpen(true)
    setModalType(type)
    setModalProps(props || {})
  }

  function closeModal() {
    setIsModalOpen(false)
    setModalType(undefined)
  }

  const memoisedValue = useMemo(() => {
    return {
      openModal,
      closeModal,
      isModalOpen,
      modalProps,
      modalType
    }
  }, [isModalOpen, modalType, modalProps])

  return (
    <ModalContext.Provider value={memoisedValue}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)
