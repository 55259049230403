type ColorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'black'
  | 'black-underlined'
  | 'green'

export interface OnClickLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  color?: ColorType
}

// Separate link component for using with an `onClick` handler
// Requires extra utility classes to appear visually similar to other links
export const OnClickLink = ({
  className = '',
  children,
  color = 'primary',
  ...props
}: OnClickLinkProps) => {
  const buildLinkColorClasses = () => {
    switch (color) {
      case 'primary':
        return 'text-primary hover:text-primaryDark'
      case 'secondary':
        return 'text-secondary hover:text-secondaryDark'
      case 'tertiary':
        return 'text-tertiary hover:text-tertiaryDark'
      case 'black':
        return 'text-shark hover:text-primary'
      case 'black-underlined':
        return 'text-shark hover:text-primary underline'
      case 'green':
        return 'text-mostlyGreen hover:text-primary no-underline'
      default:
        return ''
    }
  }

  return (
    <a
      className={`font-medium cursor-pointer transition-colors duration-150 ease-in-out ${buildLinkColorClasses()} ${className}`}
      {...props}
    >
      {children}
    </a>
  )
}
